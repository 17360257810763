import React, {useEffect, useState} from "react";
import FileUpload from "../../../components/inputs/FileUpload";
import {useUpload} from "../../../hooks/useUpload";
import TextInput from "../../../components/inputs/TextInput";
import Spinner from "../../../components/Spinner";
import Checkbox from "../../../components/inputs/Checkbox";

const ItemUpload = () => {
    const type = 'item'

    const {upload, isLoading, error, success} = useUpload();
    const [name, setName] = useState("");
    const [displayedMessage, setDisplayedMessage] = useState(null);
    const [displayedError, setDisplayedError] = useState(null);
    const [model, setModel] = useState(null);
    const [textures, setTextures] = useState({});
    const [mcMetas, setMcMetas] = useState({});
    const [textureInputs, setTextureInputs] = useState([])
    const [isVanilla, setIsVanilla] = useState({});

    useEffect(() => {
        if (error) {
            setDisplayedMessage(error);
        } else if (displayedError) {
            setDisplayedMessage(displayedError);
        } else if (success) {
            setDisplayedMessage(success);
        }
    }, [error, success, displayedError]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const lowercaseName = name.toLowerCase();

        const namePattern = /^[a-z0-9._-]+$/;
        if (!namePattern.test(lowercaseName)) {
            alert(
                "The name can only contain lowercase letters, numbers, periods, underscores, and hyphens."
            );
            return;
        }

        if (!model || Object.keys(textures).length !== textureInputs.length - Object.entries(isVanilla).filter(([k,v]) => v).length) {
            setDisplayedError("Model and textures must be selected!");
            return
        }

        const formData = new FormData();
        formData.append("name", lowercaseName);
        formData.append("model", model);
        for (const [key, value] of Object.entries(textures)) {
            if (!isVanilla[key]) {
                formData.append("texturenames", key)
                formData.append("texturefiles", value)
            }
        }
        for (const [key, value] of Object.entries(mcMetas)) {
            if (!isVanilla[key]) {
                formData.append("mcmetanames", key)
                formData.append("mcmetafiles", value)
            }
        }
        for (const [key, value] of Object.entries(isVanilla)) {
            if (value) {
                formData.append("vanillatexturenames", key);
            }
        }

        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }

        upload(type, formData);
    };

    useEffect(() => {
        if (model !== null) {
            const reader = new FileReader();
            reader.readAsText(model, "UTF-8");
            reader.onload = (e) => {
                const json = JSON.parse(e.target.result);
                if (json.hasOwnProperty("textures")) {
                    const values = [...new Set(Object.values(json["textures"]))]
                    const fileInputs = values.map(value => (
                        <tr key={value}>
                            <td className="mt-4 text-xl uppercase font-semibold whitespace-nowrap text-white text-center">{value}</td>
                            <td className="justify-center"><Checkbox
                                checked={isVanilla[value]}
                                onChange={() => {
                                    const tempIsVanilla = isVanilla
                                    tempIsVanilla[value] = !tempIsVanilla[value]
                                    setIsVanilla(tempIsVanilla)
                                }}
                            /></td>
                            <td><FileUpload
                                fileType=".png"
                                onFileChange={(e) => {
                                    const tempTextures = textures
                                    tempTextures[value] = e.target.files[0]
                                    setTextures(tempTextures)
                                }}
                                onClear={() => {
                                    const tempTextures = textures
                                    delete tempTextures[value]
                                    setTextures(tempTextures)
                                }}
                            /></td>
                            <td><FileUpload
                                fileType=".mcmeta"
                                onFileChange={(e) => {
                                    const tempMcMetas = mcMetas
                                    tempMcMetas[value] = e.target.files[0]
                                    setMcMetas(tempMcMetas)
                                }}
                                onClear={() => {
                                    const tempMcMetas = mcMetas
                                    delete tempMcMetas[value]
                                    setMcMetas(tempMcMetas)
                                }}
                            /></td>
                        </tr>
                    ))
                    setTextureInputs(fileInputs)
                } else {
                    setTextureInputs([])
                }
                setTextures({})
                setMcMetas({})
            }
        }
    }, [model, isVanilla]);

    useEffect(() => {
        if (success) {
        setModel(null);
        setTextures([]);
        setMcMetas([]);
        setName("");
        }
    }, [success]);

    return isLoading ? <Spinner/> : (
        <form onSubmit={handleSubmit} className="text-white">
            <div className="flex flex-col gap-3">
                <TextInput onTextChange={(e) => setName(e.target.value)} name={name}/>
                <hr/>
                <FileUpload
                    label="Model"
                    fileType=".json"
                    onFileChange={(e) => setModel(e.target.files[0])}
                />
                <hr/>
                <table className="table-fixed text-sm w-full justify-center">
                    <thead>
                    <tr key="$HEADERS">
                        <th scope="col" className="px-6 py-3">
                            Name
                        </th>
                        <th scope="col" className="px-6 py-3 w-2">
                            Vanilla
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Texture
                        </th>
                        <th scope="col" className="px-6 py-3">
                            McMeta
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {textureInputs.length > 0 ? textureInputs : null}
                    </tbody>
                </table>
            </div>
            <button
                disabled={isLoading}
                type="submit"
                className={`mt-4 w-1/4 cursor-pointer inline-block rounded hover:bg-indigo-400 bg-indigo-600 px-4 py-2 text-sm font-medium text-white transition hover:scale-110 hover:shadow-xl focus:outline-none focus:text-primary-700 disabled:bg-stone-500 disabled:text-stone-300`}
            >
                Upload
            </button>
            <div
                className={`${
                    error || displayedError
                        ? "m-auto text-center w-1/4 border border-red-500 rounded-lg text-red-500 bg-red-100 mt-5"
                        : success
                            ? "m-auto text-center w-1/4 border border-green-500 rounded-lg text-green-500 bg-red-100 mt-5"
                            : "hidden"
                }`}
            >
                {displayedMessage}
            </div>
        </form>
    );
};

export default ItemUpload;
