import {useState} from "react";
import axios from "axios";
import {useTokenCheck} from "./useTokenCheck";

export const useList = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;

    const [list, setList] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const {checkToken} = useTokenCheck(false)

    const getList = async (type, page, pageSize) => {
        setList([]);
        setError(null);
        setIsLoading(true);

        try {
            checkToken()
                .then(() => {
                    axios.get(`${backendUrl}/${type}/download?page=${page}&pageSize=${pageSize}`)
                        .then((response) => {
                            setList(response.data.content);
                            setTotalPages(response.data.totalPages)
                        }).catch((err) => {
                            if (err.response) {
                                setError(err.response.data);
                            } else {
                                setError("Unknown error occurred");
                            }
                        });
                });
        } catch (err) {
            setError('Network error. Please try again.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    return {getList, list, totalPages, isLoading, error}
}