import React, {useEffect, useState} from 'react'
import UploadButton from './buttons/UploadButton';
import NavButton from './buttons/NavButton';
import {useLocation} from "react-router-dom";

const Navbar = () => {
    const username = JSON.parse(localStorage.getItem("user"))?.username;
    const [uploadVisible, setUploadVisible] = useState(false)
    const location = useLocation();

    useEffect(() => {
        setUploadVisible(location.pathname !== '/')
    }, [location])

    return (
        <div
            className={`bg-gray-800 sticky top-0 text-white p-2 grid ${uploadVisible ? "grid-cols-3" : "grid-cols-2"} w-screen`}>
            <h1 className='self-center text-left'><span className='font-bold'>{username}</span></h1>
            {uploadVisible ? <UploadButton location={location.pathname}/> : null}
            <div className='flex gap-2 justify-end'>
                <NavButton method={"navigate"} page={"home"}/>
                <NavButton method={"navigate"} page={"tooltip"}/>
                <NavButton method={"navigate"} page={"item"}/>
                <NavButton method={"navigate"} page={"armour"}/>
                <NavButton method={"navigate"} page={"elytra"}/>
                <NavButton method={"logout"}/>
            </div>
        </div>
    )
}

export default Navbar