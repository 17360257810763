import "./App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AuthenticatedRouteGuard, UnauthenticatedRouteGuard} from "./components/RouteGuard";
import TooltipUpload from "./pages/items/tooltip/Upload";
import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import Verify from "./pages/auth/Verify";
import TooltipList from "./pages/items/tooltip/List";
import TooltipElement from "./pages/items/tooltip/Element";
import ElytraList from "./pages/items/elytra/List";
import ElytraElement from "./pages/items/elytra/Element";
import ElytraUpload from "./pages/items/elytra/Upload";
import ArmourList from "./pages/items/armour/List";
import ArmourElement from "./pages/items/armour/Element";
import ArmourUpload from "./pages/items/armour/Upload";
import ItemList from "./pages/items/item/List";
import ItemElement from "./pages/items/item/Element";
import ItemUpload from "./pages/items/item/Upload";


function App() {
    const signupEnabled = process.env.REACT_APP_SIGNUP_ENABLED == "true";

    return (
        <div className="absolute justify-center text-center w-screen h-full bg-gray-700">
            <BrowserRouter>
                <Routes>
                    <Route element={<AuthenticatedRouteGuard/>}>
                        <Route
                            exact path="/"
                            element={<Home/>}
                        />
                        <Route
                            exact path="/tooltip"
                            element={<TooltipList/>}
                        />
                        <Route
                            exact path="/tooltip/element"
                            element={<TooltipElement/>}
                        />
                        <Route
                            exact path="/tooltip/upload"
                            element={<TooltipUpload/>}
                        />
                        <Route
                            exact path="/item"
                            element={<ItemList/>}
                        />
                        <Route
                            exact path="/item/element"
                            element={<ItemElement/>}
                        />
                        <Route
                            exact path="/item/upload"
                            element={<ItemUpload/>}
                        />
                        <Route
                            exact path="/armour"
                            element={<ArmourList/>}
                        />
                        <Route
                            exact path="/armour/element"
                            element={<ArmourElement/>}
                        />
                        <Route
                            exact path="/armour/upload"
                            element={<ArmourUpload/>}
                        />
                        <Route
                            exact path="/elytra"
                            element={<ElytraList/>}
                        />
                        <Route
                            exact path="/elytra/element"
                            element={<ElytraElement/>}
                        />
                        <Route
                            exact path="/elytra/upload"
                            element={<ElytraUpload/>}
                        />
                    </Route>
                    <Route element={<UnauthenticatedRouteGuard/>}>
                        <Route
                            exact path="/login"
                            element={<Login/>}
                        />
                        {signupEnabled ? <Route
                            exact path="/signup"
                            element={<Signup/>}
                        /> : null}
                        <Route
                            exact path="/verify"
                            element={<Verify/>}
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
