import React, {useEffect, useState} from "react";

const Pagination = ({totalPages, onChange}) => {
    const [pageNav, setPageNav] = useState([])
    const [page, setPage] = useState(0)

    const className = (i) => {
        return `relative cursor-pointer inline-block rounded ${i - 1 === page ? "bg-indigo-400" : "bg-indigo-600"} px-3 py-1.5 text-sm font-medium text-white transition hover:scale-110 hover:shadow-xl focus:outline-none active:bg-indigo-500 hover:bg-indigo-400 focus:bg-neutral-100 focus:text-primary-700 active:text-primary-700`
    }
    const disabledClassName = "relative inline-block rounded bg-gray-400 px-3 py-1.5 text-sm font-medium text-white"

    useEffect(() => {
        const pageNavElements = []
        for (let i = 1; i <= totalPages; i++) {
            pageNavElements.push(
                <li className="m-1" key={`${i}`}>
                    <a className={className(i)} onClick={() => setPage(i - 1)}>{i}</a>
                </li>
            )
        }
        setPageNav(pageNavElements)
        onChange(page)
    }, [page, totalPages])

    /* https://tw-elements.com/docs/standard/navigation/pagination/# */
    return (
        <div className="mt-4 flex w-full justify-center items-center">
            <nav aria-label="List navigation">
                <ul className="list-style-none flex">
                    <li className="m-1" key="prev">
                        <a className={page === 0 ? disabledClassName : className(-1)}
                           onClick={() => setPage(Math.max(page - 1, 0))}
                        >Previous</a>
                    </li>
                    {pageNav}
                    <li className="m-1" key="next">
                        <a
                            className={page === totalPages - 1 ? disabledClassName : className(-1)}
                            onClick={() => setPage(Math.min(page + 1, totalPages - 1))}
                        >Next</a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Pagination