import React from 'react'

const Home = () => {
    return (
        <div className="text-white text-xl font-semibold mt-4">
            <h1 className="text-3xl">Resource Pack Generator</h1>
            <p>Upload your textures and models here and they'll magically appear in-game!</p>
        </div>
    )
}

export default Home