import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

const Verify = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;
    const [searchParams, setSearchParams] = useSearchParams();
    const [message, setMessage] = useState("Redirecting...");
    const navigate = useNavigate();

    const fetchData = async () => {
        await fetch(
            `${backendUrl}/auth/verify?code=${searchParams.get("code")}`,
            {
                method: "GET",
            }
        ).then((response) => {
            if (response.ok) {
                setTimeout(() => {
                    navigate("/login");
                }, 5000);
            } else {
                setMessage("Failed to verify, invalid code!");
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return <div className="text-xl text-white font-semibold">{message}</div>;
};

export default Verify;
