import React, {useEffect, useState} from "react";
import {useElement} from "../../../hooks/useElement";
import {useNavigate, useSearchParams} from "react-router-dom";
import Spinner from "../../../components/Spinner";
import axios from "axios";
import {useTokenCheck} from "../../../hooks/useTokenCheck";


const ItemElement = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;
    const type = 'item'

    const {getElement, dto, isLoading, error} = useElement()
    const [searchParams, setSearchParams] = useSearchParams();
    const {checkToken} = useTokenCheck(false)
    const [displayedError, setDisplayedError] = useState('')
    const navigate = useNavigate();
    const [textures, setTextures] = useState([])

    useEffect(() => {
        if (dto == null) {
            getElement(type, searchParams.get("id"))
        }
    }, [])

    useEffect(() => {
        if (dto !== null) {
            setTextures(dto.textures.map(texture =>
                    <div className="flex items-center mx-6">
                        <img className="min-h-24 max-h-24" src={`data:image/png;base64, ${texture}`}
                             alt=''/>
                    </div>
                )
            )
        }
    }, [dto]);

    return isLoading || dto == null ? <Spinner/> : (error ?
            <div className="m-auto text-center w-1/4 border border-red-500 rounded-lg text-red-500 bg-red-100 mt-5">
                {error}
            </div>
            : (
                <div>
                    <p className="mt-4 uppercase font-semibold text-white text-xl">{dto.name}</p>
                    <hr className="mt-4"/>
                    {textures.length > 0 ?
                        <>
                        <p className="mt-4 text-xl uppercase font-semibold whitespace-nowrap text-white text-center">Textures</p>
                        <div className="flex justify-center mt-4">{textures}</div>
                        </> : null
                    }
                    {dto.userOwned ? <>
                        <hr className="mt-4"/>
                        <a
                            className={`${isLoading ? "pointer-events-none cursor-none bg-stone-500 text-stone-300" : "pointer-events-auto cursor-pointer bg-red-600 text-white transition hover:scale-110 hover:shadow-xl focus:outline-none hover:bg-red-400 focus:bg-red-400 focus:text-primary-700"} mt-4 cursor-pointer inline-block rounded px-4 py-2 text-sm font-medium`}
                            onClick={() => {
                                checkToken().then(() => {
                                    axios.delete(`${backendUrl}/${type}/delete/${dto.name}`)
                                        .then((response) => {
                                            navigate(`/${type}`)
                                        }).catch((err) => {
                                        setDisplayedError(`Error deleting ${type}`)
                                    });
                                });
                            }}
                        >
                            <p>Delete</p>
                        </a>
                        {displayedError ? <div
                            className="m-auto text-center w-1/4 border border-red-500 rounded-lg text-red-500 bg-red-100 mt-5">
                            {displayedError}
                        </div> : null}
                    </> : null}
                </div>
            )
    )
}

export default ItemElement;