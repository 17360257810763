import React, {useRef, useState} from "react";

function FileUpload({label, fileType, onFileChange, onClear}) {
    const [fileName, setFileName] = useState("Select a file");
    const fileInputRef = useRef(null); // Create a ref for the file input

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name); // Update file name on selection
            onFileChange(event); // Trigger parent onFileChange handler
        }
    };

    const handleClick = () => {
        fileInputRef.current.click(); // Trigger click on file input using ref
    };

    const handleClear = () => {
        setFileName("Select a file"); // Update file name on selection
        onClear();
    }

    const cols = label && onClear ? "grid-cols-4" : (label || onClear ? "grid-cols-3" : "grid-cols-2")

    return (
        <div className={`grid ${cols} gap-3 m-auto w-1/3 mt-4 items-center`}>
            {label ? <p>{label}</p> : null}
            <input
                type="file"
                accept={fileType}
                onChange={handleFileChange}
                ref={fileInputRef} // Assign ref to file input
                className="hidden" // Hide the file input
            />
            {onClear ?
                <button
                    type="button"
                    className="flex self-center w-max m-auto gap-2 py-1 px-2 pr-4 font-semibold text-white bg-indigo-600 transition hover:scale-110 hover:bg-indigo-400 hover:shadow-xl rounded-md cursor-pointer whitespace-nowrap"
                    onClick={handleClear}
                >
                    <h1>Clear</h1>
                </button> : null
            }
            <button
                type="button"
                className="flex self-center w-max m-auto gap-2 py-1 px-2 pr-4 font-semibold text-white bg-indigo-600 transition hover:scale-110 hover:bg-indigo-400 hover:shadow-xl rounded-md cursor-pointer whitespace-nowrap"
                onClick={handleClick}
            >
                <h1>Browse...</h1>
            </button>
            <div className="header flex-1 w-full flex ">
                <p>{fileName}</p>

            </div>
        </div>
    );
}

export default FileUpload;
