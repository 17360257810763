import {Navigate, Outlet} from 'react-router-dom';
import Navbar from './Navbar';
import Spinner from './Spinner';
import {useTokenCheck} from '../hooks/useTokenCheck';

export const AuthenticatedRouteGuard = () => {
    const {isTokenValid} = useTokenCheck(true);

    if (isTokenValid === null) return <Spinner/>;

    return isTokenValid ? <div><Navbar/><Outlet/></div> : <Navigate to="/login"/>;
};

export const UnauthenticatedRouteGuard = () => {
    const {isTokenValid} = useTokenCheck(true);

    if (isTokenValid === null) return <Spinner/>;

    return isTokenValid ? <Navigate to="/"/> : <Outlet/>;
};