import React, {useEffect, useState} from 'react'
import {useList} from "../../../hooks/useList";
import Spinner from "../../../components/Spinner";
import Pagination from "../../../components/Pagination";
import {useNavigate} from "react-router-dom";

const ItemList = () => {
    const type = 'item'

    const {getList, list, totalPages, isLoading, error} = useList()
    const [items, setItems] = useState([])
    const pageSize = 6
    const navigate = useNavigate()

    useEffect(() => {
        setItems(list.map(dto => (
            <>
                <tr className="cursor-pointer bg-gray-800 border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    onClick={() => navigate(`/${type}/element?id=${dto.name}`)}
                    key={dto.name}>
                    <th scope="row"
                        className="px-6 py-4 text-xl uppercase font-semibold whitespace-nowrap text-white">
                        {dto.name}
                    </th>
                    <td className="px-6 py-4">
                        {dto.textures.length > 0 ? <div className="flex justify-center">
                            <img className="min-h-16 max-h-16" src={`data:image/png;base64, ${dto.textures[0]}`}
                                 alt={`${dto.name} item`}/>
                        </div> : null}
                    </td>
                </tr>
                <hr className='w-screen'/>
            </>
        )))
    }, [list])

    return isLoading ? <Spinner/> : (
        <div>
            <Pagination
                totalPages={totalPages}
                onChange={(page) => getList(type, page, pageSize)}
            />
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-screen">
                <table className="table-fixed text-sm w-full justify-center text-gray-400">
                    <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                    <tr key="$HEADERS">
                        <th scope="col" className="px-6 py-3">
                            Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Texture
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {items}
                    </tbody>
                </table>
            </div>


        </div>
    )
}

export default ItemList