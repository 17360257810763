import React from 'react'

const TextInput = ({name, onTextChange}) => {
    const handleTextChange = (event) => {
        onTextChange(event);
    };

    return (
        <div className="grid grid-cols-3 gap-3 m-auto w-1/3 mt-4 items-center ">
            <p className='m-auto'>Name</p>
            <input
                type="text"
                value={name}
                onChange={handleTextChange}
                required
                minLength="1"
                maxLength="24"
                className=" text-black p-2 col-span-2 w-3/5 ml-12 rounded-md h-full "
            />
        </div>
    )
}

export default TextInput