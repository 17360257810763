import React, {useEffect, useState} from "react";
import {vs2015} from 'react-syntax-highlighter/dist/esm/styles/hljs'
import SyntaxHighlighter from 'react-syntax-highlighter';
import {useElement} from "../../../hooks/useElement";
import {useNavigate, useSearchParams} from "react-router-dom";
import Spinner from "../../../components/Spinner";
import axios from "axios";
import {useTokenCheck} from "../../../hooks/useTokenCheck";


const TooltipElement = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;
    const type = 'tooltip'

    const {getElement, dto, isLoading, error} = useElement()
    const [searchParams, setSearchParams] = useSearchParams();
    const {checkToken} = useTokenCheck(false)
    const [displayedError, setDisplayedError] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        if (dto == null) {
            getElement(type, searchParams.get("id"))
        }
    }, [])

    return isLoading || dto == null ? <Spinner/> : (error ?
        <div className="m-auto text-center w-1/4 border border-red-500 rounded-lg text-red-500 bg-red-100 mt-5">
            {error}
        </div>
        : (
            <div>
                <p className="mt-4 uppercase font-semibold text-white text-xl">{dto.name}</p>
                <div className="flex w-screen mt-4 justify-center">
                    <table>
                        <tr>
                            <td className="px-6 py-3">
                                <div className="flex justify-center">
                                    <img className="min-h-16 max-h-16" src={`data:image/png;base64, ${dto.frameImage}`}
                                         alt={`${dto.name} frame`}/>
                                </div>
                            </td>
                            <td className="px-6 py-3">
                                <div className="flex justify-center">
                                    <img className="min-h-16 max-h-16" src={`data:image/png;base64, ${dto.backgroundImage}`}
                                         alt={`${dto.name} frame`}/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="px-6 py-3">
                                <div className="text-left drop-shadow-lg">
                                    <SyntaxHighlighter language="json" style={vs2015}>
                                        {JSON.stringify(JSON.parse(dto.frameMcMeta), undefined, 2)}
                                    </SyntaxHighlighter>
                                </div>
                            </td>
                            <td className="px-6 py-3">
                                <div className="text-left drop-shadow-lg">
                                    <SyntaxHighlighter language="json" style={vs2015}>
                                        {JSON.stringify(JSON.parse(dto.backgroundMcMeta), undefined, 2)}
                                    </SyntaxHighlighter>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                {dto.userOwned ? <>
                    <hr className="mt-4" />
                    <a
                        className={`${isLoading ? "pointer-events-none cursor-none bg-stone-500 text-stone-300" : "pointer-events-auto cursor-pointer bg-red-600 text-white transition hover:scale-110 hover:shadow-xl focus:outline-none hover:bg-red-400 focus:bg-red-400 focus:text-primary-700"} mt-4 cursor-pointer inline-block rounded px-4 py-2 text-sm font-medium`}
                        onClick={() => {
                            checkToken().then(() => {
                                axios.delete(`${backendUrl}/${type}/delete/${dto.name}`)
                                    .then((response) => {
                                        navigate(`/${type}`)
                                    }).catch((err) => {
                                        setDisplayedError(`Error deleting ${type}`)
                                    });
                            });
                        }}
                    >
                        <p>Delete</p>
                    </a>
                    {displayedError ? <div
                        className="m-auto text-center w-1/4 border border-red-500 rounded-lg text-red-500 bg-red-100 mt-5">
                        {displayedError}
                    </div> : null}
                </> : null}
            </div>
        )
    )
}

export default TooltipElement;