import {useAuthContext} from "./useAuthContext"
import {useNavigate} from 'react-router-dom'
import axios from "axios";

export const useLogout = () => {
    const {dispatch} = useAuthContext()
    const navigate = useNavigate()
    const logout = () => {
        localStorage.removeItem('user')
        delete axios.defaults.headers.common['Authorization']
        dispatch({type: 'LOGOUT'})
        navigate('/login')
    }

    return {logout}
}