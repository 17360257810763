import {useState} from 'react';
import {useAuthContext} from './useAuthContext';
import axios from "axios";


export const useLogin = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {dispatch} = useAuthContext();

    const login = async (username, password) => {
        setIsLoading(true);
        setError(null);

        try {
            delete axios.defaults.headers.common['Authorization']
            axios.post(`${backendUrl}/auth/login`, {
                username: username,
                password: password
            }).then((response) => {
                localStorage.setItem('user', JSON.stringify(response.data));
                axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`
                dispatch({type: 'LOGIN', payload: response.data});
            }).catch((err) => {
                if (err.response) {
                    setError(err.response.data);
                } else {
                    setError("Unknown error occurred");
                }
            });
        } catch (err) {
            setError('Network error. Please try again.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    return {login, isLoading, error};
};
