import React from "react";
import {useNavigate} from "react-router-dom";

const UploadButton = ({location}) => {
    const navigate = useNavigate()

    return (
        <button
            className="flex self-center w-max m-auto gap-2 py-1 px-2 pr-4 font-semibold text-white bg-indigo-600 shadow-[0_1px_2px_rgba(0,0,128,0.4),0_0_0_1px_rgba(0,0,128,0.761)] transition hover:scale-110 hover:bg-indigo-400 hover:shadow-xl border border-white/30 rounded-md cursor-pointer whitespace-nowrap"
            onClick={() => {
                navigate(`${location.split('/').slice(0, 2).join('/')}/upload`)
            }}>
            <span className="flex items-center">
                <svg
                    className="h-6 w-6"
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill="none" d="M0 0h24v24H0z" stroke="none"></path>
                    <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"></path>
                    <path d="M9 15l3 -3l3 3"></path>
                    <path d="M12 12l0 9"></path>
                </svg>
            </span>
            <span className="capitalize">Upload {location.split('/')[1]}</span>
        </button>
    );
};

export default UploadButton;