import {useEffect, useState} from 'react';
import {jwtDecode} from 'jwt-decode';
import {useAuthContext} from './useAuthContext';
import axios from "axios";

export const useTokenCheck = (checkOnUse) => {
    const [isTokenValid, setIsTokenValid] = useState(null);
    const {dispatch} = useAuthContext();
    const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;

    const checkToken = async () => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const accessToken = storedUser ? storedUser.accessToken : null;
        const refreshToken = storedUser ? storedUser.refreshToken : null;

        if (!accessToken) {
            setIsTokenValid(false);
            return;
        }

        try {
            const decodedAccessToken = jwtDecode(accessToken);
            const decodedRefreshToken = jwtDecode(refreshToken);
            const currentTime = Date.now() / 1000;

            if (decodedRefreshToken.exp < currentTime) {
                setIsTokenValid(false);
                dispatch({type: 'LOGOUT'});
                localStorage.removeItem('user');
                return;
            }

            if (decodedAccessToken.exp < currentTime) {
                console.log('Token has expired, verifying with backend...');
                delete axios.defaults.headers.common["Authorization"]
                await axios.post(`${backendUrl}/auth/token`, {
                    refreshToken: refreshToken
                }).then((response) => {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`
                    setIsTokenValid(true);
                }).catch((err) => {
                    setIsTokenValid(false);
                    dispatch({type: 'LOGOUT'});
                    localStorage.removeItem('user');
                });
            } else {
                setIsTokenValid(true);
            }
        } catch (error) {
            console.error('Token validation failed:', error);
            setIsTokenValid(false);
        }
    };

    useEffect(() => {
        if (checkOnUse) {
            checkToken();
        }
    }, []);

    return {checkToken, isTokenValid};
};