import React, {useEffect, useState} from 'react'
import {useList} from "../../../hooks/useList";
import Spinner from "../../../components/Spinner";
import Pagination from "../../../components/Pagination";
import {useNavigate} from "react-router-dom";

const TooltipList = () => {
    const type = 'tooltip'

    const {getList, list, totalPages, isLoading, error} = useList()
    const [tooltips, setTooltips] = useState([])
    const pageSize = 8
    const navigate = useNavigate()

    useEffect(() => {
        setTooltips(list.map(dto => (
            <>
                <tr className="cursor-pointer bg-gray-800 border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    onClick={() => navigate(`/${type}/element?id=${dto.name}`)}
                    key={dto.name}>
                    <th scope="row"
                        className="px-6 py-4 text-xl uppercase font-semibold whitespace-nowrap text-white">
                        {dto.name}
                    </th>
                    <td className="px-6 py-4">
                        <div className="flex justify-center">
                            <img className="min-h-12 max-h-12" src={`data:image/png;base64, ${dto.frameImage}`}
                                 alt={`${dto.name} frame`}/>
                        </div>
                    </td>
                    <td className="px-6 py-4">
                        <div className="flex justify-center">
                            <img className="min-h-12 max-h-12" src={`data:image/png;base64, ${dto.backgroundImage}`}
                                 alt={`${dto.name} frame`}/>
                        </div>
                    </td>
                </tr>
                <hr className='w-screen'/>
            </>
        )))
    }, [list])

    return isLoading ? <Spinner/> : (
        <div>
            <Pagination
                totalPages={totalPages}
                onChange={(page) => getList(type, page, pageSize)}
            />
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-screen">
                <table className="table-fixed w-full text-sm justify-center text-gray-400">
                    <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                    <tr key="$HEADERS">
                        <th scope="col" className="px-6 py-3">
                            Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Background
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Frame
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {tooltips}
                    </tbody>
                </table>
            </div>


        </div>
    )
}

export default TooltipList