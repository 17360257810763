import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useLogout} from "../../hooks/useLogout";

const NavButton = ({page, method}) => {
    const {logout} = useLogout();
    const location = useLocation();
    const [active, setActive] = useState(false)
    const colour = method === "logout" ? "bg-red-600" : "bg-indigo-600"
    const hoverColour = method === "logout" ? "bg-red-400" : "bg-indigo-400"

    useEffect(() => {
        setActive((page === "home" && location.pathname === "/") || location.pathname === "/" + page)
    }, [location]);

    const handleLogout = () => {
        logout();
    };

    if (method === "logout") {
        page = "logout"
    }

    const navigate = useNavigate();
    return (
        <div>
            <a
                className={`cursor-pointer inline-block rounded ${active ? hoverColour : colour} px-4 py-2 text-sm font-medium text-white transition hover:scale-110 hover:shadow-xl focus:outline-none hover:${hoverColour} focus:${hoverColour} focus:text-primary-700`}
                onClick={() => {
                    if (method === "navigate") {
                        page === "home" ? navigate(`/`) : navigate(`/${page}`);
                    } else if (method === "logout") {
                        handleLogout();
                    } else {
                        return null;
                    }
                }}
            >
                <p className="capitalize">{page}</p>
            </a>
        </div>
    );
};

export default NavButton;
