import {useState} from "react";
import axios from "axios";
import {useTokenCheck} from "./useTokenCheck";

export const useUpload = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL;

    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {checkToken} = useTokenCheck(false)

    const upload = async (type, formData) => {
        setError(null);
        setIsLoading(true);
        setSuccess(null)
        try {
            checkToken()
                .then(() => {
                    axios.post(`${backendUrl}/${type}/upload`, formData)
                        .then((response) => {
                            setSuccess(response.data)
                        }).catch((err) => {
                            if (err.response) {
                                setError(err.response.data);
                            } else {
                                setError("Unknown error occurred");
                            }
                        });
                });
        } catch (err) {
            setError('Network error. Please try again.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    return {upload, isLoading, success, error}
}