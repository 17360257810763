import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLogin} from "../../hooks/useLogin";

const Login = () => {
    const navigate = useNavigate();
    const signupEnabled = process.env.REACT_APP_SIGNUP_ENABLED == "true";

    const storedUser = localStorage.getItem("user");
    if (storedUser !== null) {
        navigate("/");
    }

    const {login, isLoading, error} = useLogin();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [displayedMessage, setDisplayedMessage] = useState(null);

    useEffect(() => {
        if (error !== null) {
            setDisplayedMessage(error)
        }
    }, [error])

    const handleSubmit = async (e) => {
        e.preventDefault();
        await login(username, password);
        setPassword("")
    };

    return (
        <div>
            <div className="flex flex-col items-center justify-center h-screen dark">
                <div className="w-full max-w-md bg-gray-800 rounded-lg shadow-md p-6">
                    <h2 className="text-2xl font-bold text-gray-200 mb-4">Login</h2>
                    <form className="flex flex-col" onSubmit={handleSubmit}>
                        <input
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                            className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                            type="username"
                            required
                        />
                        <input
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                            type="password"
                            required
                        />
                        {signupEnabled ? <div className="flex items-center justify-between flex-wrap">
                            <p className="text-white mt-4">
                                {" "}
                                Don't have an account?{" "}
                                <a
                                    className="text-sm text-blue-500 -200 hover:underline mt-4 cursor-pointer"
                                    onClick={() => navigate("/signup")}
                                >
                                    Signup
                                </a>
                            </p>
                        </div> : null}
                        <button
                            disabled={isLoading}
                            className="disabled:bg-stone-500 disabled:text-stone-300 bg-gradient-to-r from-indigo-500 to-blue-500 text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
                            type="submit"
                        >
                            Login
                        </button>
                    </form>
                    <div
                        className={`${displayedMessage ? "text-center border border-red-500 rounded-lg text-red-500 bg-red-100 mt-5" : "hidden"}`}>
                        {displayedMessage}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
