import React, {useEffect, useState} from "react";
import FileUpload from "../../../components/inputs/FileUpload";
import {useUpload} from "../../../hooks/useUpload";
import TextInput from "../../../components/inputs/TextInput";
import Spinner from "../../../components/Spinner";

const TooltipUpload = () => {
    const type = 'tooltip'

    const {upload, isLoading, error, success} = useUpload();
    const [name, setName] = useState("");
    const [displayedMessage, setDisplayedMessage] = useState(null);
    const [displayedError, setDisplayedError] = useState(null);
    const [backgroundMcMeta, setBackgroundMcMeta] = useState(null);
    const [frameMcMeta, setFrameMcMeta] = useState(null);
    const [backgroundPng, setBackgroundPng] = useState(null);
    const [framePng, setFramePng] = useState(null);

    useEffect(() => {
        if (error) {
            setDisplayedMessage(error);
        } else if (displayedError) {
            setDisplayedMessage(displayedError);
        } else if (success) {
            setDisplayedMessage(success);
        }
    }, [error, success, displayedError]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const lowercaseName = name.toLowerCase();

        const namePattern = /^[a-z0-9._-]+$/;
        if (!namePattern.test(lowercaseName)) {
            alert(
                "The name can only contain lowercase letters, numbers, periods, underscores, and hyphens."
            );
            return;
        }

        const formData = new FormData();
        formData.append("name", lowercaseName);
        formData.append("backgroundMcMeta", backgroundMcMeta);
        formData.append("frameMcMeta", frameMcMeta);
        formData.append("backgroundImage", backgroundPng);
        formData.append("frameImage", framePng);

        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }

        if (!frameMcMeta || !backgroundMcMeta || !backgroundPng || !framePng) {
            setDisplayedError("All files must be selected!");
            return
        }

        upload(type, formData);
    };

    useEffect(() => {
        setBackgroundMcMeta(null);
        setBackgroundPng(null);
        setFrameMcMeta(null);
        setFramePng(null);
        setName("");
    }, [success]);

    return isLoading ? <Spinner/> : (
        <form onSubmit={handleSubmit} className="text-white">
            <div className="flex flex-col gap-3 ">
                <TextInput onTextChange={(e) => setName(e.target.value)} name={name}/>
                <hr/>
                <h1 className="text-xl font-bold">Background</h1>
                <FileUpload
                    label="PNG"
                    fileType=".png"
                    onFileChange={(e) => setBackgroundPng(e.target.files[0])}
                />
                <FileUpload
                    label="McMeta"
                    fileType=".mcmeta"
                    onFileChange={(e) => setBackgroundMcMeta(e.target.files[0])}
                />
                <hr/>
                <h1 className="text-xl font-bold">Frame</h1>
                <FileUpload
                    label="PNG"
                    fileType=".png"
                    onFileChange={(e) => setFramePng(e.target.files[0])}
                />
                <FileUpload
                    label="McMeta"
                    fileType=".mcmeta"
                    onFileChange={(e) => setFrameMcMeta(e.target.files[0])}
                />
                <hr/>
            </div>
            <button
                disabled={isLoading}
                type="submit"
                className={`mt-4 w-1/4 cursor-pointer inline-block rounded hover:bg-indigo-400 bg-indigo-600 px-4 py-2 text-sm font-medium text-white transition hover:scale-110 hover:shadow-xl focus:outline-none focus:text-primary-700 disabled:bg-stone-500 disabled:text-stone-300`}
            >
                Upload
            </button>
            <div
                className={`${
                    error || displayedError
                        ? "m-auto text-center w-1/4 border border-red-500 rounded-lg text-red-500 bg-red-100 mt-5"
                        : success
                            ? "m-auto text-center w-1/4 border border-green-500 rounded-lg text-green-500 bg-red-100 mt-5"
                            : "hidden"
                }`}
            >
                {displayedMessage}
            </div>
        </form>
    );
};

export default TooltipUpload;
